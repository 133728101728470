<template>
  <div class=" ">
    <feed v-if="$store.state.userToken" />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import inbox from "@/components/inbox.vue";
import feed from "@/components/feed.vue";
export default {
  name: "Home",
  components: {
    feed,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      projs: [],
      loadingProj: true,
      publi: [],
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
